import 'soapbox/main';

import React from 'react';
import { createRoot } from 'react-dom/client';

import ColorSwatch from './color-swatch';

const mmaSocial = document.createElement('div');
mmaSocial.id = 'mmasocial';
document.body.appendChild(mmaSocial);

if (mmaSocial) {
  const root = createRoot(mmaSocial);
  root.render(<ColorSwatch />);
}
