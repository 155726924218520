import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the shape of a single tab instance
interface TabInstance {
  activeItem: string;
}

// Define the shape of the tabs slice state
interface TabsState {
  instances: Record<string, TabInstance>; // Using `Record` to represent a dictionary of instances
}

// Define the payload shape for setting the active item
interface SetActiveItemPayload {
  instanceId: string;
  activeItem: string;
}

// Initialize the state with a typesafe structure
const initialState: TabsState = {
  instances: {},
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveItem: (state, action: PayloadAction<SetActiveItemPayload>) => {
      const { instanceId, activeItem } = action.payload;
      // Initialize the instance if it doesn't exist
      if (!state.instances[instanceId]) {
        state.instances[instanceId] = { activeItem };
      } else {
        state.instances[instanceId].activeItem = activeItem;
      }
    },
  },
});

// Export the action creators and the reducer
export const { setActiveItem } = tabsSlice.actions;

export default tabsSlice.reducer;
